export const links = [
  {
    label: 'Easy Dashboard',
    to: '/styleguide/SEasyDashboardLayout',
  },
  {
    label: 'Full Screen Layout',
    to: '/styleguide/SFullScreenLayout'
  },
  {
    label: 'Half Screen Layout',
    to: '/styleguide/SHalfScreenLayout'
  },
  {
    label: 'Home',
    to: '/styleguide/'
  },
  {
    label: 'CryptoSelectMenu',
    to: '/styleguide/SCryptoSelectMenu'
  },
  {
    label: 'Tabs',
    to: '/styleguide/tabs'
  },
  {
    label: 'Table',
    to: '/styleguide/STable'
  },
  {
    label: 'LeadText',
    to: '/styleguide/SLeadText'
  },
  {
    label: 'CoreCard',
    to: '/styleguide/SCoreCard'
  },
  {
    label: 'Badge',
    to: '/styleguide/Badge'
  },
  {
    label: 'ActionButton',
    to: '/styleguide/SActionButton'
  },
  {
    label: 'CopyToClipboard',
    to: '/styleguide/SCopyToClipboard'
  },
  {
    label: 'Descriptionlist',
    to: '/styleguide/SDescriptionList'
  },
  {
    label: 'QRDisplayModal',
    to: '/styleguide/SQRDisplayModal'
  },
  {
    label: 'Accordions',
    to: '/styleguide/SAccordions'
  },
  {
    label: 'RadioCardGroupSimple',
    to: '/styleguide/SRadioCardGroupSimple'
  },
  {
    label: 'RadioCardGroupDetailed',
    to: '/styleguide/SRadioCardGroupDetailed'
  },
  {
    label: 'Token',
    to: '/styleguide/SToken'
  },
  {
    label: 'Channel Capacity Meter ',
    to: '/styleguide/SChannelCapacityMeter'
  },
  {
    label: 'Steps',
    to: '/styleguide/SSteps'
  },
  {
    label: 'Asset Channel card',
    to: '/styleguide/SAssetChannelCard'
  },
  {
    label: 'InputNumber',
    to: '/styleguide/SInputNumber'
  },
  {
    label: 'Easy',
    children: [
      {
        label: 'Token',
        to: '/styleguide/easy/SEasyToken'
      },
      {
        label: 'QuickActionButton',
        to: '/styleguide/easy/SQuickActionButton'
      }
      ,
      {
        label: 'EasyPercentageSelector',
        to: '/styleguide/easy/SEasyPercentageSelector'
      }
      ,
      {
        label: 'EasyLabelAndValueList',
        to: '/styleguide/easy/SEasyLabelAndValueList'
      }
    ]
  }
];
